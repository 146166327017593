// 1、本地mock的使用  在home.js文件中先创建一个方法，并返回数据

import json from '@/assets/index.json'

export default {
  getData: () => {
    return {
      code: 200,
      data: json
    }
  }
}