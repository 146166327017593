import { render, staticRenderFns } from "./Dynamic.vue?vue&type=template&id=339fc418&scoped=true&"
import script from "./Dynamic.vue?vue&type=script&lang=js&"
export * from "./Dynamic.vue?vue&type=script&lang=js&"
import style0 from "./Dynamic.vue?vue&type=style&index=0&id=339fc418&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "339fc418",
  null
  
)

export default component.exports