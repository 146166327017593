<template>
  <section>
    <div class="nav-footer" v-if="!$store.state.common.isMobile">
      <div
        class="nav-container"
        v-for="(item, index) in navFooterList"
        :key="index"
      >
        <div class="title" @click="jumpTo(item.link)">{{ item.title }}</div>
        <div class="nav-item-container">
          <div
            class="nav-item"
            v-for="(subItem, subIndex) in item.menus"
            :key="subIndex"
            @click="jumpTo(subItem.link)"
          >
            {{ subItem.name }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="tips"
      @click="jumpTo('https://beian.miit.gov.cn/#/Integrated/index')"
    >
      <img src="@/assets/img/foot.jpg" style="width: 0.2rem" />
      Copyright © 2019 苏州太湖雪丝绸股份有限公司 版权所有 苏ICP备11043445号-1
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      widthSize:
        document.documentElement.clientWidth || document.body.clientWidth,
      widthBlo: true,
      navFooterList: [
        {
          title: "首页",
          link: "/home",
          menus: [],
        },
        {
          title: "走进太湖雪",
          menus: [
            {
              name: "公司简介",
              link: "/enter/intro",
            },
            {
              name: "公司视频",
              link: "/enter/video",
            },
            {
              name: "公司历程",
              link: "/enter/history",
            },
            {
              name: "创始人风采",
              link: "/enter/founder",
            },
            {
              name: "公司荣誉",
              link: "/enter/honour",
            },
            {
              name: "企业文化",
              link: "/enter/culture",
            },
          ],
        },
        {
          title: "新闻中心",
          link: "/news",
          menus: [
            {
              name: "公司新闻",
              link: "/news",
            },
          ],
        },
        {
          title: "产品中心",
          link: "/product/cansibei",
          menus: [
            {
              name: "蚕丝被",
              link: "/product/cansibei",
            },
            {
              name: "床品套件",
              link: "/product/taojian",
            },
            {
              name: "丝绸饰品",
              link: "/product/shipin",
            },
            {
              name: "丝绸服饰",
              link: "/product/fushi",
            },
          ],
        },
        {
          title: "十佳蚕丝被",
          link: "/top10",
          menus: [
            {
              name: "产地",
              link: "/top10",
            },
            {
              name: "体感",
              link: "/top10",
            },
            {
              name: "实力",
              link: "/top10",
            },
            {
              name: "成分",
              link: "/top10",
            },
            {
              name: "原料",
              link: "/top10",
            },
            {
              name: "服务",
              link: "/top10",
            },
            {
              name: "研发",
              link: "/top10",
            },
            {
              name: "生态",
              link: "/top10",
            },
            {
              name: "工艺",
              link: "/top10",
            },
            {
              name: "创新",
              link: "/top10",
            },
          ],
        },
        {
          title: "销售渠道",
          link: "/channel/offline",
          menus: [
            {
              name: "线下门店",
              link: "/channel/offline",
            },
            {
              name: "天猫旗舰店",
              link: "https://taihuxue.tmall.com/",
            },
            {
              name: "京东自营店",
              link: "https://mall.jd.com/index-1000080881.html",
            },
            {
              name: "海外官网",
              link: "https://thxsilk.com/",
            },
            {
              name: "海外定制",
              link: "https://taihusnow.com/",
            },
          ],
        },
        {
          title: "企业团购",
          link: "/group-purchase",
          menus: [],
        },
        {
          title: "投资者关系",
          link: "/relations/service",
          menus: [
            {
              name: "投资者服务",
              link: "/relations/service",
            },
            {
              name: "公司公告",
              link: "http://"+location.host+"/123.html",
            },
            {
              name: "分红配送",
              link: "/relations/bonus",
            },
          ],
        },
        {
          title: "联系我们",
          link: "/contactus/contactus",
          menus: [
            {
              name: "招贤纳士",
              link: "https://www.liepin.com/company/8863377/",
            },
            {
              name: "联系我们",
              link: "/contactus/contactus",
            },
          ],
        },
      ],
    };
  },
  created() {
    if (this.widthSize <= 750) {
      this.widthBlo = false;
    }
  },
  methods: {
    jumpTo(link) {
      if (link.startsWith("http")) {
        window.open(link, "_blank");
      } else {
        this.$router.push(link);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.nav-footer {
  width: 100%;
  display: flex;
  justify-content: space-around;
  color: #aaa;
  background: #f8f8f8;
  padding: 0.6rem 1.5rem;
  .nav-container {
    .title {
      color: #333;
      line-height: 0.52rem;
      font-size: 0.16rem;
      cursor: pointer;
    }
    .nav-item-container {
      .nav-item {
        color: #8c8c8c;
        line-height: 38px;
        cursor: pointer;
        font-size: 12px;
      }
    }
  }
  & > .nav-container:nth-child(5) {
    .nav-item-container {
      width: 1rem;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .nav-item {
        margin-right: 0.16rem;
      }
    }
  }
}
.tips {
  width: 100%;
  text-align: center;
  font-size: 12px;
  color: #aaa;
  height: 0.5rem;
  line-height: 0.5rem;
}
@media screen and (max-width: 1400px) {
  .nav-footer {
    .nav-container {
      .title {
        color: #333;
      }
      .nav-item-container {
        .nav-item {
          color: #999;
        }
      }
    }
    & > .nav-container:nth-child(5) {
      .nav-item-container {
        width: 1.4rem;
        display: flex;
        flex-wrap: wrap;
        .nav-item {
          margin-right: 0.2rem;
        }
      }
    }
  }
}
@media screen and (max-width: 997px) {
  margin-bottom: 2.4rem;
  .nav-footer {
    .nav-container {
      .title {
        color: #333;
      }
      .nav-item-container {
        .nav-item {
          color: #999;
        }
      }
    }
    & > .nav-container:nth-child(5) {
      .nav-item-container {
        width: 1.4rem;
        display: flex;
        flex-wrap: wrap;
        .nav-item {
          margin-right: 0.1rem;
        }
      }
    }
  }
  .tips {
    position: fixed;
    bottom: 0;
    height: 2.2rem;
    line-height: 1.1rem;
  }
}
</style>
