<template>
  <div id="app">
    <!-- 头部组件 -->
    <Header :boxList="boxList"></Header>
    <!-- 占位高度 -->
    <!-- <div style="height:76px"></div> -->
    <!-- 底部边框线 -->
    <!-- <div class="BottomSide"></div> -->
    <!-- 展示路由 -->
    <router-view style="margin-top: 1rem;"></router-view>

    <!-- 底部组件 -->
    <Footer></Footer>

    <!-- 侧边提示 -->
    <!-- <div class="sideNav">
      <ul @click="showBox">
        <li :class="boxList">
          <router-link to="/case">案例</router-link>
          <router-link to="/service">服务</router-link>
          <router-link to="/about">关于</router-link>
          <router-link to="/Dynamic">动态</router-link>
          <router-link to="/Research">研究</router-link>
        </li>
        <li class="boxList">
          <img :src="ImgSrc" alt="" width="100%">
        </li>
      </ul>

      <div @click="GoTops" v-show="scrollTops" class="topImg">
        <img width="100%" src="./assets/img/top.png" alt="">
      </div>
    </div> -->
    <!-- 遮罩层 -->
    <div class="maskLayer" v-if="classNames" @click="hideBox"></div>

  </div>
</template>

<script>
import WOW from 'wow.js'
import search from '@/assets/img/search.png'
import returnImg from '@/assets/img/return.png'
export default {
  data() {
    return {
      // 顶部高度
      scrollTops: null,
      scrollTop: null,
      // 图片
      ImgSrc: search,
      // 侧边栏展开的类名
      boxList: 'boxList2',
      // 遮罩层
      classNames: '',

      setSide: null
    }
  },
  created() {
    //在窗口滚动时调用监听窗口滚动方法
    window.addEventListener('scroll', this.windowScrollListener)
    // 发送请求
    this.$bus.$on('setChange', val => {
      this.setSide = val
    })
    this.initWOW()
    this.$store.dispatch("common/setIsMobile", this.isMobile());
  },
  methods: {
    isMobile: () => {
      var mobile_flag = false;
      // 根据浏览器头判断是否移动端
      if (
        /Android|iPhone|SymbianOS|Windows Phone|iPad|iPod/.test(
          navigator.userAgent
        )
      ) {
        mobile_flag = true;
      }
      var screen_width = window.screen.width;
      var screen_height = window.screen.height;
      // 根据屏幕分辨率判断是否是手机
      if (screen_width < 768) {
        mobile_flag = true;
      }
      return mobile_flag;
    },
    // 初始化wow.js
    initWOW(){
      const wow = new WOW({
        boxClass: 'wow',
        animateClass: 'animated',
        offset: 0,
        mobile: true,
        live: true
      });
      wow.init();
    },
    //监听窗口滚动
    windowScrollListener() {
      //获取操作元素最顶端到页面顶端的垂直距离
      this.scrollTop = document.body.scrollTop || document.documentElement.scrollTop
      // console.log(scrollTop)
      if (this.scrollTop >= 200) {
        this.scrollTops = true //离顶部大于200显示
      }
      if (this.scrollTop < 200) {
        this.scrollTops = false
      }
    },
    // 返回顶部
    GoTops() {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
      // 返回顶部时消除遮罩层，让侧边栏收回
      this.boxList = 'boxList2'
      this.classNames = ''
    },
    // 点击侧边栏
    showBox() {
      if (this.boxList === 'boxList2') {
        // 切换图片
        this.ImgSrc = returnImg
        // 展示
        this.boxList = 'boxList2 boxListTips'
        // 展示遮罩层
        this.classNames = 1
      } else {
        this.hideBox()
      }
      this.setSide = true

      this.$bus.$emit('setSide', true)
    },
    // 点击遮罩层
    hideBox() {
      this.classNames = ''
      this.boxList = 'boxList2'
      this.ImgSrc = search
    }
  },
  watch: {
    setSide(newVal) {
      if (!newVal) {
        this.hideBox()
      }
    }
  },

  beforeDestroy() {
    //离开页面时删除该监听
    window.removeEventListener('scroll', this.windowScrollListener)
  }
}
</script>

<style lang="less" >
* {
  cursor: default;
}
.BottomSide {
  width: 100%;
  border-bottom: 1px solid #ccc;
}
.sideNav {
  position: fixed;
  right: 2%;
  top: 50%;
  transform: translateY(-50%);
  background: #24b727;
  text-align: center;
  z-index: 999;

  > ul {
    display: flex;

    transition: width 1s;
    > .boxList {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
    > .boxList2 {
      display: flex;
      flex-direction: column;
      width: 0;
      height: 50px;
      overflow: hidden;
      transition: width 0.5s;
      > a {
        display: block;
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        border: 1px solid #ccc;
        color: #fff;
      }
    }
    > .boxListTips {
      flex-direction: row;
      justify-content: flex-start;
      width: 250px;
    }
    > .boxList {
      line-height: 0;
    }
  }
  > .topImg {
    position: absolute;
    right: 0;
    top: 52px;
    width: 50px;
    height: 50px;
    background: #24b727;
  }
}

//遮罩层
.maskLayer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background: #ccc;
  opacity: 0.2;
}
</style>
