<template>
  <section>
    <!-- <div class="headImg"><img src="../assets/img/about1.jpg" alt=""></div> -->
    <Swiper :swiper="swipe"></Swiper>
    <section class="about">
      <section>
        <h1>我们是设计师、工程师、梦想者<br> 也是您的用户体验专家
        </h1>
        <p>我们是一家专注用户体验设计开发与互联网品牌建设的设计公司，创立至今为200多位客户提供了创新与专业的设计方案。设计服务范围包括：交互原型设计、产品视觉设计、网站设计与开发建设、移动及软件产品界面设计、图标设计、品牌及平面设计等。我们的价值与宗旨是为用户和客户打造最nice的设计，用设计提升产品和企业价值。</p>
      </section>
    </section>
  </section>
</template>

<script>
import about1 from '@/assets/img/about1.jpg'
import about from '@/assets/img/case1.jpg'
export default {
  data() {
    return {
      swipe: [{ src: about1 }, { src: about }]
    }
  }
}
</script>

<style lang="less" scoped>
.headImg {
  width: 100%;
  img {
    width: 100%;
  }
}
.about {
  width: 1200px;
  margin: 50px auto;
  background: url('../assets/img/about.jpg') center top no-repeat;
  background-attachment: fixed;
  background-size: cover;
  padding: 50px;

  > section {
    h1 {
      padding: 20px;
      font-size: 24px;
      color: #fff;
      text-align: center;
    }
    p {
      font-size: 14px;
      color: #e2e2e2;
      line-height: 28px;
      width: 80%;
      margin: 40px auto;
      text-align: left;
    }
  }
}
@media screen and (max-width: 1200px) {
  .about {
    width: 100%;
  }
}
</style>