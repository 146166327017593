const state = {
  isMobile: 'false',
}

const mutations = {
  SET_IS_MOBILE: (state, isMobile) => {
    state.isMobile = isMobile
  }
}

const actions = {
  setIsMobile({ commit }, isMobile) {
    commit('SET_IS_MOBILE', isMobile)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
