<template>
  <div class="business">
    <section>
      <dl v-for="(item,index) in list" :key="index">
        <dt><img v-lazy="item.url" alt=""></dt>
        <dd>{{item.name}}</dd>
        <dd>{{item.base}}</dd>
      </dl>
    </section>
  </div>
</template>

<script>
import { getData } from '@/api/index'

export default {
  data() {
    return {
      list: []
    }
  },
  // business
  async mounted() {
    const { getData: item } = await getData()
    this.list = item.data.caseList.business
  }
}
</script>

<style lang="less" scoped>
.business {
  width: 1200px;
  text-align: center;
  margin: 20px auto;

  > h1 {
    font-size: 26px;
    line-height: 35px;
    margin-bottom: 20px;
    color: #444;
  }
  > h3 {
    font-size: 16px;
    color: #666;
  }
  > section {
    display: flex;
    flex-wrap: wrap;
    > dl {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 33%;
      margin-top: 20px;
      > dt {
        overflow: hidden;
        > img {
          width: 100%;
          transition: transform 0.5s;
          &:hover {
            transform: scale(110%);
          }
        }
      }
      > dd {
        &:nth-of-type(1) {
          font-weight: 600;
          font-size: 16px;
          margin: 10px 0 5px 0;
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .business {
    width: 100%;
  }
}
// 手机端样式   750px 以下
@media screen and (max-width: 750px) {
  .business {
    > section {
      > dl {
        width: 100%;
        align-items: center;
      }
    }
  }
}
</style>