<template>
  <div>
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item v-for="(item,index) in swiperList" :key="item.index">
        <img :src="item.src" alt="">
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
export default {
  props: ['swiperList'],
}
</script>

<style lang="less" scoped>
.my-swipe .van-swipe-item {
  font-size: 20px;
  // line-height: 60px;
  text-align: center;
  img {
    width: 100%;
  }
}
</style>