<template>
  <section>
    <div class="headImg"><img src="../assets/img/service1.jpg" alt=""></div>

    <section class="service">
      <h1>我们专注用户体验设计和开发</h1>
      <section class="serviceList">
        <dl v-for="(item,index) in list" :key="index">
          <dt><img :src="item.url" alt=""></dt>
          <dd v-for="(itemChild,index) in item.base" :key="index">{{itemChild.dl}}</dd>
        </dl>
      </section>
    </section>
  </section>
</template>

<script>
import { getData } from '@/api/index'

export default {
  data() {
    return {
      list: []
    }
  },

  async mounted() {
    const { getData: item } = await getData()

    this.list = item.data.devise
  }
}
</script>

<style lang="less" scoped>
.headImg {
  width: 100%;
  img {
    width: 100%;
  }
}
.service {
  width: 1200px;
  text-align: center;
  margin: 50px auto;
  > h1 {
    font-size: 28px;
    color: #333;
    line-height: 30px;
    text-align: center;
    margin: 30px 0;
  }
  section {
    margin-top: 80px;
    display: flex;
    justify-content: space-between;
    > dl {
      > dd {
        font-size: 12px;
        line-height: 24px;
        color: #666;
      }
      > dd:nth-of-type(1) {
        font-size: 20px;
        margin: 25px 0 5px 0;
        color: #333;
      }
    }
  }
}
@media screen and (max-width: 1300px) {
  .service {
    width: 100%;
  }
}

// 1170px - 750px的样式

@media screen and (max-width: 1170px) and (min-width: 750px) {
  .service {
    .serviceList {
      flex-wrap: wrap;
      > dl {
        margin-top: 10px;
        width: 33%;
      }
    }
  }
}

// 手机端样式   750px 以下
@media screen and (max-width: 750px) {
  .service {
    .serviceList {
      flex-wrap: wrap;
      > dl {
        margin-top: 10px;
        width: 50%;
      }
    }
  }
}
</style>