<template>
  <section class="case">
    <div class="showImg">
      <img src="../assets/img/case1.jpg">
    </div>
    <nav>
      <router-link class="tips" to="all">全部</router-link>
      <router-link class="tips" to="website">网站</router-link>
      <router-link class="tips" to="move">移动</router-link>
      <router-link class="tips" to="software">软件界面</router-link>
      <router-link class="tips" to="internet">互联网产品</router-link>
      <router-link class="tips" to="business">电子商务</router-link>
      <router-link class="tips" to="brand">品牌&平面</router-link>
    </nav>
    <hr>
    <!-- 展示 -->
    <router-view></router-view>

  </section>
</template>

<script>
export default {
  data() {
    return {
      tips: 'tips'
    }
  }
}
</script>

<style lang="less" scoped>
.router-link-exact-active {
  color: #3fca25;
  border-bottom: 2px solid #3fca25;
}
.case {
  > .showImg {
    width: 100%;
    > img {
      width: 100%;
    }
  }
  nav {
    display: flex;
    justify-content: space-between;
    width: 1200px;
    margin: 20px auto;

    > a {
      font-size: 20px;
      font-weight: 500;
      text-align: center;
      &:hover {
        border-bottom: 2px solid #3fca25;
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .case {
    nav {
      width: 100%;
      justify-content: space-around;
    }
  }
}

@media screen and (max-width: 750px) {
  .case {
    nav {
      justify-content: space-between;
      flex-flow: wrap;
      padding: 0;

      a {
        margin: 10px 7px;
        width: 30%;
        padding: 5px !important;
        border: 1px solid #ccc;
      }
      .router-link-exact-active {
        color: #fff;
        background: #3fca25;
      }
    }
  }
}
@media screen and (max-width: 420px) {
  .case {
    nav {
      a {
        margin: 10px 4px;
        width: 45%;
        padding: 5px !important;
        border: 1px solid #ccc;
      }
      .router-link-exact-active {
        color: #fff;
        background: #3fca25;
      }
    }
  }
}
</style>