<template>
  <section v-show="isShow">
    <section class="header hidden-xs">
      <header>
        <div class="header_left">
          <img   src="@/assets/img/logo-son.png" @click="goHome()" style="width:55%;"  />
        </div>
        <!-- 右侧所有路由 -->
        <div class="header_right">
          <div class="header_title">
            <span class="title">首页</span>
            <span class="icon glyphicon glyphicon-menu-down"></span>
          </div>
          <router-link
            v-for="(item, index) in navHeaderList"
            :key="index"
            :to="item.link"
            >{{ item.title }}</router-link
          >
        </div>
      </header>
    </section>
    <!-- 手机导航 -->
    <section>
      <div class="header-nav-m container-fuild visible-xs">
        <div class="header-nav-m-menu text-center">
          {{ menuName }}
          <div
            class="header-nav-m-menu-wrapper"
            @click="menuClick"
          >
            <span :class="menuClass"></span>
          </div>
          <ul id="menu" class="header-nav-m-wrapper" :class="this.isActive ? '' : 'hidden'">
            <li
              v-for="(item, index) in navHeaderList1"
              :key="index"
              :class="index == navIndex ? 'active' : ''"
              @click="navClick(index, item.title)"
            >
              <router-link :to="item.link">
                {{ item.title }}
                <i class="underline"></i>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
export default {
  computed: {
    navHeaderList() {
      return this.navHeaderList1;
    },
  },
  data() {
    return {
      navIndex: sessionStorage.getItem("navIndex")
        ? sessionStorage.getItem("navIndex")
        : 0,
      menuName: "首页",
      menuClass: "glyphicon glyphicon-menu-down",
      navHeaderList1: [
        {
          title: "首页",
          link: "/home",
        },
        {
          title: "走进太湖雪",
          link: "/enter",
        },
        {
          title: "新闻中心",
          link: "/news",
        },
        {
          title: "产品中心",
          link: "/product",
        },
        {
          title: "十佳蚕丝被",
          link: "/top10",
        },
        {
          title: "销售渠道",
          link: "/channel",
        },
        {
          title: "企业团购",
          link: "/group-purchase",
        },
        {
          title: "投资者关系",
          link: "/relations",
        },
        {
          title: "联系我们",
          link: "/contactus",
        },
      ],
      navHeaderList2: [
        {
          title: "首页",
          link: "/home",
        },
        {
          title: "走进太湖雪",
          link: "/enter",
        },
        {
          title: "新闻中心",
          link: "/news",
        },
        {
          title: "产品中心",
          link: "/product",
        },
        {
          title: "投资者关系",
          link: "/relations",
        },
        {
          title: "联系我们",
          link: "/contactus",
        },
      ],
      isShow: true,
      isActive: false,
    };
  },
  mounted() {
    // 监听滚动
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    this.handleDestroyListener();
  },
  methods: {
    // 处理滚动
    handleScroll() {
      const scrollTop = window.pageYOffset;
      if (scrollTop < 80) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
    },
    handleDestroyListener() {
      window.removeEventListener("scroll", this.handleScroll, true);
    },
    goHome() {
      this.$router.push("/home");
    },
    navClick(index, name) {
      this.navIndex = index;
      sessionStorage.setItem("navIndex", index);
      this.menuName = name;
      this.menuClick();
    },
    menuClick() {
      if (this.menuClass == "glyphicon glyphicon-menu-down") {
        this.menuClass = "glyphicon glyphicon-menu-up";
        this.isActive = false
      } else {
        this.menuClass = "glyphicon glyphicon-menu-down";
        this.isActive = true
      }
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  line-height: 0.46rem;
  margin: auto;
  padding: 0.3rem 0;
  header {
    display: flex;
    justify-content: space-between;
    padding: 0 1.8rem;
    .header_title {
      display: none;
    }
    .header_left,
    .header_right {
      font-size: 0.18rem;
    }
    .header_left {
      flex: 2;
      img {
        cursor: pointer;
        height: 0.46rem;
      }
    }
    .header_right {
      display: flex;
      justify-content: space-around;
      flex: 5;
      cursor: pointer;
      .router-link-active {
        color: #24b727;
        border-bottom: 2px solid #24b727;
      }
      > a {
        padding: 0 0.1rem;
        color: #000;
        cursor: pointer;
        &:hover {
          border-bottom: 2px solid #24b727;
        }
      }
    }
  }
}

.home-bcg {
  width: 100%;
  height: 90vh;
  background: url("@/assets/img/home/home_banner_1.jpg") no-repeat fixed center;
}
.isActive {
  background-color: rgba(255, 255, 255, 0.7);
}
.hidden{
  display: none;
}
@media screen and (max-width: 1400px) {
  .header {
    header {
      width: 100%;
      padding: 0 0.5rem;
      .header_left {
        flex: 1;
      }
      .header_right {
        flex: 6;
        .router-link-active {
          color: #24b727;
          border-bottom: 2px solid #24b727;
        }
        > a {
          padding: 0 0.1rem;
          color: #000;
          cursor: pointer;
          &:hover {
            border-bottom: 2px solid #24b727;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 997px) {
  .header-nav-m {
    position: relative;
    /* 导航栏logo容器 */
    .header-nav-m-logo {
      height: 80px;
      position: relative;
      /* 导航栏logo图片 */
      img {
        width: 95px;
        height: 45px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
    }
    /* 导航栏  菜单容器 */
    .header-nav-m-menu {
      color: #fff;
      height: 50px;
      font-size: 20px;
      line-height: 50px;
      background: #474747;
      position: relative;
    }
    /* 导航栏 菜单图标 */
    .header-nav-m-menu-wrapper {
      position: absolute;
      top: 50%;
      right: 20px;
      margin-top: -20px;
      width: 50px;
      height: 40px;
      color: #fff;
      z-index: 999999;
      font-size: 12px;
    }
    /* 导航栏 */
    .header-nav-m-wrapper {
      position: absolute;
      top: 50px;
      left: 0;
      width: 100%;
      background: #474747;
      z-index: 999;
    }
    /* 导航栏 每个导航 */
    .header-nav-m-wrapper > li {
      height: 40px;
      line-height: 40px;
      border-bottom: 1px solid #ccc;
    }
    /* 导航栏 每个导航下面的 a 链接 */
    .header-nav-m-wrapper > li > a {
      color: #fff;
      font-size: 15px;
      font-weight: bold;
      padding: 15px 0;
      position: relative;
    }
    /* 导航栏 每个导航下面的 a 链接的右侧小三角 */
    .header-nav-wrapper > li > a > span {
      font-size: 10px;
    }
  }
}
</style>
