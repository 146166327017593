import Vue from "vue";
import VueRouter from "vue-router";

// 获取原型对象push函数
const originalPush = VueRouter.prototype.push;
// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace;
// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};

import About from "../views/About";
import Case from "../views/Case";
import Dynamic from "../views/Dynamic";
import Research from "../views/Research";
import Service from "../views/Service";

import All from "../views/case/All";
import Internet from "../views/case/internet";
import Brand from "../views/case/brand";
import Business from "../views/case/business";
import Move from "../views/case/move";
import Website from "../views/case/website";
import Software from "../views/case/software";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    component: () => import("../views/home"),
  },
  {
    path: "/enter",
    component: () => import("../views/enter"),
    children: [
      {
        path: "/",
        redirect: "/enter/intro",
      },
      {
        path: "/enter/intro",
        component: () => import("../views/enter/intro"),
      },
      {
        path: "/enter/video",
        component: () => import("../views/enter/video"),
      },
      {
        path: "/enter/history",
        component: () => import("../views/enter/history/index.vue"),
      },
      {
        path: "/enter/founder",
        component: () => import("../views/enter/founder"),
      },
      {
        path: "/enter/honour",
        component: () => import("../views/enter/honour"),
      },
      {
        path: "/enter/standard",
        component: () => import("../views/enter/standard"),
      },
      {
        path: "/enter/culture",
        component: () => import("../views/enter/culture"),
      },
    ],
  },
  {
    path: "/news",
    component: () => import("../views/news"),
    children: [
      {
        path: "/news/detail",
        component: resolve => require(['@/views/news/detail'], resolve),
      }
    ]
  },
  {
    path: "/news-detail",
    component: () => import("../views/news/detail"),
  },
  {
    path: "/top10",
    component: () => import("../views/top"),
  },
  {
    path: "/product",
    component: () => import("../views/product"),
    children: [
        {
          path: "/",
          redirect: "/product/cansibei",
        },
        {
          path: "/product/cansibei",
          component: () => import("../views/product/cansibei"),
        },
        {
          path: "/product/taojian",
          component: () => import("../views/product/taojian"),
        },
        {
          path: "/product/shipin",
          component: () => import("../views/product/shipin"),
        },
        {
            path: "/product/fushi",
            component: () => import("../views/product/fushi"),
        }
    ]
  },
  {
    path: "/channel",
    component: () => import("../views/channel"),
    children: [
        {
          path: "/",
          redirect: "/channel/offline",
        },
        {
          path: "/channel/offline",
          component: () => import("../views/channel/offline"),
        },
        {
          path: "/channel/tianmao",
          component: () => import("../views/channel/tianmao"),
        },
        {
          path: "/channel/jd",
          component: () => import("../views/channel/jd"),
        },
        {
            path: "/channel/overseas",
            component: () => import("../views/channel/overseas"),
        }
    ]
  },
  {
    path: "/group-purchase",
    component: () => import("../views/groupPurchase"),
  },
  {
    path: "/relations",
    component: () => import("../views/relations"),
    children: [
        {
          path: "/",
          redirect: "/relations/service",
        },
        {
          path: "/relations/service",
          component: () => import("../views/relations/service"),
        },
        {
          path: "/relations/announce",
          component: () => import("../views/relations/announce"),
        },
        {
          path: "/relations/bonus",
          component: () => import("../views/relations/bonus"),
        },
      {
        path: "/relations/periodical",
        component: () => import("../views/relations/periodical"),
      },
      {
        path: "/relations/esg",
        component: () => import("../views/relations/esg"),
      }
    ]
  },
  {
    path: "/contactus",
    component: () => import("../views/contactus"),
    children: [
      {
        path: "/",
        redirect: "/contactus/contactus",
      },
      {
        path: "/contactus/contactus",
        component: () => import("../views/contactus/contactus"),
      },
    ]
  },
  {
    path: "/about",
    component: About,
  },
  {
    path: "/case",
    component: Case,
    children: [
      {
        path: "/case",
        redirect: "/case/all",
      },
      {
        path: "/case/all",
        component: All,
      },
      {
        path: "/case/internet",
        component: Internet,
      },
      {
        path: "/case/brand",
        component: Brand,
      },
      {
        path: "/case/business",
        component: Business,
      },
      {
        path: "/case/move",
        component: Move,
      },
      {
        path: "/case/website",
        component: Website,
      },
      {
        path: "/case/software",
        component: Software,
      },
    ],
  },
  {
    path: "/dynamic",
    component: Dynamic,
  },
  {
    path: "/research",
    component: Research,
  },
  {
    path: "/service",
    component: Service,
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, form, next) => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  window.pageXOffset = 0;

  next();
});

export default router;
