import axios from 'axios'

const request = axios.create({
  timeout: 5000
})
request.interceptors.request.use(config => {
  // config 配置对象  可以在里面配置请求头 headers

  return config
})
// 配置响应拦截器
request.interceptors.response.use((response) => {

  // 成功的回调函数
  return response.data

}, (error) => {
  // 失败的回调函数

  return Promise.reject(new error('请求失败'))
})

export default request