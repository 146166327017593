<template>
  <section>
    <div class="headImg"><img src="../assets/img/dynamic1.jpg" alt=""></div>

    <!-- 三个项目 -->
    <section class="Dynamic">
      <div class="project">
        <div class="title">
          <h1>如何练就优秀的项目体验</h1>
          <span>创意、流程、执行缺一不可</span>
        </div>
        <ul class="proBox">
          <li v-for="(item,index) in demo" :key="index">
            <img :src="item.src" alt="">
            <p>{{item.title}}</p>
          </li>
        </ul>
      </div>
    </section>
  </section>
</template>

<script>
import { getData } from '@/api/index'

export default {
  data() {
    return {
      demo: []
    }
  },
  async mounted() {
    const { getData: item } = await getData()
    this.demo = item.data.demo
  }
}
</script>

<style lang="less" scoped>
.headImg {
  width: 100%;
  img {
    width: 100%;
  }
}
.project {
  width: 1200px;
  margin: 50px auto;

  .title {
    text-align: center;

    > h1 {
      font-size: 28px;
      color: #333;
      line-height: 35px;
      margin: 15px 0;
    }
  }

  > .proBox {
    display: flex;
    justify-content: space-between;
    margin: 50px 0;

    > li {
      width: 30%;

      > img {
        width: 100%;

        &:hover {
          box-shadow: 0px 2px 5px 0px #ccc, 0px 2px 10px 0px #ccc;
        }
      }

      > p {
        font-size: 14px;
        line-height: 24px;
        color: #666;
        padding-top: 10px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .project {
    width: 100%;
  }
}
@media screen and (max-width: 750px) {
  .project {
    .proBox {
      flex-wrap: wrap;
      > li {
        width: 100%;
        > p {
          padding: 10px;
        }
      }
    }
  }
}
</style>