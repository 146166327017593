import Vue from 'vue'
import App from './App.vue'
import router from './router'

/* bootstarp */
import '@/assets/css/bootstrap.min.css'

// 引入normalize.css
import 'normalize.css'

// animate.css
import 'animate.css'

// 引入全局样式
import "@/assets/fonts/font.less"
import './assets/css/style.css'


import store from './store'

// 引入组件
import Header from './components/Header'
import Footer from './components/MyFooter'
import Swiper from './components/Swiper'

Vue.component('Header', Header)
Vue.component('Footer', Footer)
Vue.component('Swiper', Swiper)


// 使用vant组件库
import vants from '@/vant/index'
Vue.use(vants)

// 引入element组件库
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// 使用mock
require('@/api/mock')


Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App),
    beforeCreate() {
        Vue.prototype.$bus = this
    }
}).$mount('#app')