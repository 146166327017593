
import Vue from 'vue'
import { Swipe, SwipeItem, Button, Lazyload, } from 'vant';

const vant = {
  install: function (Vue) {
    Vue.use(Button)
    Vue.use(Swipe);
    Vue.use(SwipeItem)
    Vue.use(Lazyload)
    Vue.use(Lazyload, {
      lazyComponent: true,
    })
  }
}

export default vant

