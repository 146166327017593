<template>
  <section class="research">
    <div class="headImg" v-for="(item,index) in imgList" :key="index">
      <img v-lazy="item" alt="">
    </div>

  </section>
</template>

<script>
export default {
  data() {
    return {
      imgList: [
        require('../assets/img/swiper003.png'),
        'https://img0.baidu.com/it/u=410476708,2625048454&fm=253&fmt=auto&app=120&f=JPEG?w=1280&h=800',
        'https://img1.baidu.com/it/u=645828909,2832941895&fm=253&fmt=auto&app=138&f=JPEG?w=800&h=500',
        'https://img0.baidu.com/it/u=3699214452,914660276&fm=253&fmt=auto&app=138&f=JPEG?w=982&h=500',
        'https://img2.baidu.com/it/u=3080407323,1643761753&fm=253&fmt=auto&app=120&f=JPEG?w=1422&h=800',
        'https://img2.baidu.com/it/u=2751717144,3022135604&fm=253&fmt=auto&app=120&f=JPEG?w=1422&h=800',
        'https://img1.baidu.com/it/u=580450470,781473213&fm=253&fmt=auto&app=138&f=JPEG?w=800&h=500',
        'https://img1.baidu.com/it/u=3881065889,140700226&fm=253&fmt=auto&app=138&f=JPEG?w=800&h=500',
        require('../assets/img/1.jpg'),
        require('../assets/img/2.jpg')
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.headImg {
  width: 100%;
  img {
    width: 100%;
    margin: 2px;
  }
}
</style>